import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export default () => ({
    init() {
        CookieConsent.run({
            disablePageInteraction: true,

            guiOptions: {
                consentModal: {
                    layout: "box",
                    position: "bottom right",
                    transition: "slide",
                    flipButtons: false,
                },
                preferencesModal: {
                    layout: "box",
                    transition: "slide",
                },
            },

            categories: {
                necessary: {
                    readOnly: true,
                    enabled: true,
                },
                analytics: {
                    autoClear: {
                        cookies: [
                            {
                                name: /^(_ga|_gid)/,
                            },
                        ],
                    },
                },
            },

            language: {
                default: "en",
                autoDetect: "document",
                translations: {
                    en: "/lang/cookie-consent/en.json",
                    de: "/lang/cookie-consent/de.json",
                },
            },
        });
    },
});
