import "vidstack/player";
import "vidstack/player/layouts";
import "vidstack/player/ui";

import "vidstack/player/styles/default/theme.css";
import "vidstack/player/styles/default/layouts/video.css";

export default (playerIndex = null, loopVideo = false) => ({
    loopVideo,
    playerIndex,

    player: null,
    isInViewport: false,
    // subscriber: null,

    // canplay: false,
    // playing: false,

    init() {
        this.player = this.$refs.player;

        this.initEventListeners();
    },

    onEnter() {
        this.isInViewport = true;
        this.play();
    },

    onLeave() {
        this.isInViewport = false;
        this.pause();
    },

    pause() {
        if (this.player && this.player.state.playing) {
            this.player.paused = true;
        }
        this.player.autoplay = false;
    },

    play() {
        if (this.player && this.isInViewport) {
            this.player.paused = false;
        }
    },

    initEventListeners() {
        // this.subscriber = this.player.subscribe(({ paused, ended, canPlay, playing }) => {
        //     // this.canplay = canPlay;
        //     // this.playing = playing;
        // });
    },

    onSlideChange({ detail: index }) {
        // Pause the player if it's not the current one in a slideshow
        // Add a delay cuz when dragging the slide, the click may trigger a play event
        if (this.playerIndex !== null && index !== this.playerIndex) {
            setTimeout(() => this.pause(), 800);
        }
    },

    destroy() {
        if (this.subscriber) {
            this.subscriber();
        }
    },
});
