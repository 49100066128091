import gsap from "gsap";

export default () => ({
    isOpen: false,
    links: null,
    gsapTimeline: null,

    init() {
        this.links = this.$refs.socials.querySelectorAll("li");
    },

    openMenu() {
        if (!this.gsapTimeline) {
            this.gsapTimeline = gsap
                .timeline()
                .fromTo(
                    this.$refs.open.children,
                    { y: 0 },
                    {
                        y: -50,
                        duration: 0.3,
                        stagger: 0.05,
                        ease: "power2.inOut",
                    },
                )
                .fromTo(
                    this.$refs.socials,
                    { opacity: 0, scale: 0.6 },
                    {
                        opacity: 1,
                        scale: 1,
                        duration: 0.7,
                        ease: "power2.inOut",
                    },
                    "<0%",
                )
                .fromTo(
                    this.$refs.bg,
                    { scale: 1 },
                    { scaleX: 1.2, scaleY: 1.5, ease: "power2.out" },
                    "<0%",
                )
                .fromTo(
                    this.links,
                    { opacity: 0, x: 20 },
                    {
                        opacity: 1,
                        x: 0,
                        duration: 0.3,
                        stagger: 0.05,
                        ease: "power2.out",
                    },
                    "<50%",
                );
        }

        this.isOpen = true;
        this.gsapTimeline.reversed(!this.isOpen);
    },

    closeMenu() {
        if (this.gsapTimeline) {
            this.gsapTimeline.reversed(true);
            this.isOpen = false;
        }
    },
});
