import Swiper from "swiper";
import { Parallax, Keyboard } from "swiper/modules";
import "swiper/css";

export default () => ({
    swiper: null,
    activeSlide: 0,

    init() {
        this.initSwiper();
        this.$watch("isDesktop", () => {
            this.initSwiper();
        });
    },

    initSwiper() {
        if (this.isDesktop || this.swiper) {
            return;
        }

        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Parallax, Keyboard],
            parallax: true,
            keyboard: true,
            slidesPerView: "auto",
        });

        this.swiper.on("slideChange", () => {
            this.activeSlide = this.swiper.realIndex;
            console.log("slideChange", this.activeSlide);
        });
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    get isDesktop() {
        return this.$store.breakpoint.lg;
    },
});
