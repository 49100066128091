import Swup from "swup";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupJsPlugin from "@swup/js-plugin";
import SwupMorphPlugin from "swup-morph-plugin";
import gsap from "gsap";

export const discoverStoriesPattern = "/(de|en)/discover-stories/:bar(.*)?";
export const isHomePreviewPage = "/(de|en)/home-preview/(\\?.*)?";
export const homePattern = "/(de|en)(\\/?)(\\?.*)?";

export default new Swup({
    containers: ["#main", "#footer"],
    plugins: [
        new SwupMorphPlugin({
            containers: ["#menu"],
        }),
        // The scripts plugin is needed to run the scripts included with formie forms
        new SwupScriptsPlugin({
            head: false,
            body: true,
        }),
        new SwupProgressPlugin({ delay: 500 }),
        new SwupPreloadPlugin(),
        new SwupJsPlugin({
            animations: [
                {
                    from: [discoverStoriesPattern], // matches any route containing 'discover-stories'
                    to: [discoverStoriesPattern], // matches any route containing 'discover-stories'
                    out: async () => {
                        gsap.set(".swup-transition-next-module", {
                            zIndex: 10,
                        });
                        await gsap.to(".swup-transition-next-module", {
                            height: window.innerHeight,
                            duration: 0.7,
                        });
                    },
                    in: async () => {
                        gsap.set(".swup-transition-current-module", {
                            zIndex: 20,
                        });
                        await gsap.fromTo(
                            ".swup-transition-current-module",
                            {
                                height: window.innerHeight,
                                y: 0,
                            },
                            {
                                height: 0,
                                y: "-100vh",
                                delay: 0.2,
                                duration: 0.7,
                                onComplete: () => {
                                    gsap.set(
                                        ".swup-transition-current-module",
                                        {
                                            clearProps: "z-index",
                                        },
                                    );
                                },
                            },
                        );
                    },
                },
                {
                    from: "(.*)", // matches any route
                    to: "(.*)", // matches any route
                    out: async () => {
                        await gsap.to("#main", { opacity: 0, duration: 0.25 });
                    },
                    in: async () => {
                        await gsap.fromTo(
                            "#main",
                            { opacity: 0 },
                            { opacity: 1, duration: 0.25 },
                        );
                    },
                },
            ],
        }),
    ],
});
