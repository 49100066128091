import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        question: "[interview-question]",
        avatar: "[interview-avatar]",
    },
    questions: null,
    current: 0,

    init() {
        this.questions = Array.from(
            this.$el.querySelectorAll(this.selectors.question),
        );

        this.$nextTick(() => {
            this.setScene();
        });
    },

    destroy() {
        ScrollTrigger.killAll();
    },

    setScene() {
        this.initScrollTrigger();
    },

    initScrollTrigger() {
        this.questions.forEach((element, index) => {
            const toTransform = element.getBoundingClientRect().height - 60;
            const avatar = element.querySelector(this.selectors.avatar);

            ScrollTrigger.create({
                trigger: element,
                start: "top center",
                end: "bottom top+=50",
                // onEnter: () => {
                //     this.current = index;
                // },
                onUpdate: (self) => {
                    gsap.to(avatar, {
                        y: self.progress * toTransform - 0,
                        ease: "none",
                    });

                    if (self.progress === 1) {
                        this.current +=
                            this.current < this.questions.length - 1 ? 1 : 0;
                    }

                    if (self.progress === 0) {
                        this.current -= this.current > 0 ? 1 : 0;
                    }
                },
            });
        });
    },
});
