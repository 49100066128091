import swup from "../swup.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    rightImageHeight: 0,
    resizeHandler: null,

    init() {
        this.onResize();

        // Need to wait for the next tick after a swup transition to get the correct height
        this.resizeHandler = () => {
            this.onResize();
            // when transitioning from home, another timeout is needed
            setTimeout(this.onResize.bind(this), 200);
        };

        // this.resizeHandler = this.onResize.bind(this);
        swup.hooks.on("page:view", this.resizeHandler);
    },

    onResize() {
        this.initScene();
        // console.log(this.$refs.rightImage, this.$refs.rightImage.offsetHeight);
        if (this.isDesktop && this.$refs.rightImage) {
            this.rightImageHeight =
                this.$refs.rightImage.offsetHeight + 56 + 34; // add mt-14 and half of the pattern height
        }
    },

    destroy() {
        swup.hooks.off("page:view", this.resizeHandler);
    },

    initScene() {
        gsap.set(
            [this.$refs.rightImage, this.$refs.topImage, this.$refs.ctaButton],
            { clearProps: true },
        );
        // ScrollTrigger.killAll();

        let mm = gsap.matchMedia();

        mm.add("(min-width: 768px)", () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: "body",
                    start: "top top",
                    endTrigger: this.$el,
                    end: "bottom top",
                    scrub: 1.2,
                },
            });

            tl.to(this.$refs.rightImage, {
                y: Math.max(
                    this.$el.clientHeight -
                        this.$refs.rightImage.clientHeight -
                        56,
                    50,
                ),
            });
            tl.to(this.$refs.topImage, { y: 65 }, "<");
            tl.to(this.$refs.ctaButton, { y: 40 }, "<");
        });
    },

    get minHeight() {
        return this.isDesktop ? `${this.rightImageHeight}px` : "auto";
    },

    get isDesktop() {
        return this.$store.breakpoint.md;
    },
});
