import "vidstack/player";
import "vidstack/player/layouts";
import "vidstack/player/ui";

import "vidstack/player/styles/default/theme.css";
import "vidstack/player/styles/default/layouts/video.css";

export default (playerIndex = null, loopTime = 0) => ({
    loopTime,
    playerIndex,
    videoPlayed: false,
    player: null,
    isInViewport: false,
    currentTime: 0,

    init() {
        this.player = this.$refs.player;

        this.player.addEventListener("end", () => this.replayVideoFromTime());
    },

    onEnter() {
        this.isInViewport = true;
        if (!this.videoPlayed) {
            this.play();
        }
    },

    onVideoEnd() {
        console.log("video end");
    },

    replayVideoFromTime() {
        if (this.loopTime) {
            this.player.currentTime = this.loopTime;
            // if (!this.player.state.playing) {
            //     this.player.play();
            // }
        }
    },

    onLeave() {
        this.isInViewport = false;
        this.pause();
    },

    pause() {
        if (this.player && this.player.state.playing) {
            this.player.paused = true;
        }
        this.player.autoplay = false;
    },

    play() {
        if (this.player && this.isInViewport) {
            this.player.paused = false;
        }
    },

    destroy() {
        this.player.removeEventListener("ended", this.replayVideoFromTime);
    },
});
