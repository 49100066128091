import debounce from "../utils/debounce";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";

import Flicking from "@egjs/flicking";
import "@egjs/flicking/dist/flicking.css";

gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

export default () => ({
    flicking: null,
    inPath: [],
    boxes: [],
    inPathMiddle: [],
    outPath: [],
    yCounter: 0,
    xCounter: 0,
    xWidth: 43.31,
    yWidth: 43.52,
    slashWidth: 8.56,
    slashWidth0: 0,
    currentImageId: 0,
    zIndex: 11,
    boxStickLength: 350,
    pinedBoxesBottom: [],
    pinedBoxesMiddle: [],
    smoothSc: null,
    mouse: {
        startX: 0,
        deltaX: 0,
    },

    init() {
        this.debouncedResize = this.debouncedResize.bind(this);
        this.handleResize = debounce(this.debouncedResize);

        window.addEventListener("resize", this.handleResize);

        this.boxes = Array.prototype.slice.call(
            this.$refs.boxesWrapper.children,
            1,
        );

        this.createFilterSVG();
        this.$nextTick(() => {
            this.initProperScroll();
        });
        // setTimeout(() => {
        // }, 1);

        this.$watch("isLarge", () => {
            this.onViewportChange();
        });
    },

    destroy() {
        ScrollTrigger.killAll();
        this.killSwiper();
        this.killSmoothScroll();
        window.removeEventListener("resize", this.handleResize);
    },

    initSmoothScroll() {
        this.smoothSc = ScrollSmoother.create({
            ignoreMobileResize: true,
            smooth: 1,
            smoothTouch: 0.5,
            effects: true,
        });

        this.smoothSc.scrollTop(1);
        setTimeout(() => {
            this.smoothSc.scrollTop(0);
        }, 1);
    },

    debouncedResize() {
        this.regenerateFilterSvg();
    },

    setPinBoxes() {
        this.boxes.forEach((el, i) => this.setPinForBox(el, i));
    },

    setPinForBox(box, i) {
        // if (i > 0) {
        //     this.pinedBoxesBottom.push(
        //         ScrollTrigger.create({
        //             pin: box,
        //             pinSpacing: true,
        //             start: "top bottom-=100",
        //             end: `+=${this.boxStickLength + 40}`,
        //         }),
        //     );
        // }

        if (i < this.boxes.length - 1)
            this.pinedBoxesMiddle.push(
                ScrollTrigger.create({
                    pin: box,
                    pinSpacing: true,
                    start: "center center",
                    end: `+=${this.boxStickLength}`,
                    onEnter: () => {
                        this.setCurrentPanel(box);
                    },
                    onEnterBack: () => {
                        this.setCurrentPanel(box);
                    },
                    onLeaveBack: () => {
                        if (i === 0) {
                            this.clearCurrentSlide();
                        }
                    },
                    onUpdate: ({ isActive }) => {
                        if (isActive) {
                            this.setCurrentPanel(box);
                        }
                    },
                }),
            );
    },

    initProperScroll() {
        if (this.isLarge) {
            this.initSmoothScroll();
            this.killSwiper();
            this.setPinBoxes();
        } else {
            ScrollTrigger.killAll();
            this.killSmoothScroll();
            this.initSwiper();
            this.pinedBoxesBottom = [];
            this.pinedBoxesMiddle = [];
        }
    },

    onViewportChange() {
        this.initProperScroll();

        setTimeout(() => {
            this.clearCurrentSlide();
        }, 1000);
    },

    initSwiper() {
        this.flicking = new Flicking(this.$refs.swiper, {
            align: "prev",
            bounce: 0,
            bound: true,
            nested: true,
            useFractionalSize: true,
        });

        this.flicking.on("willChange", (el) => {
            // console.log(el.index);
            if (el.index === 0) {
                document
                    .querySelector("[x-ref=logo]")
                    .classList.remove("is-collapsed");
            } else {
                document
                    .querySelector("[x-ref=logo]")
                    .classList.add("is-collapsed");
            }
            this.setCurrentPanel(el.panel.element);
        });

        this.flicking.on("move", (ev) => {
            const visibleElements = ev.currentTarget.camera.visiblePanels.map(
                (el) => el._elProvider.element,
            );

            gsap.to(ev.currentTarget.currentPanel._elProvider.element, {
                x: -ev.axesEvent.delta.flick * 10,
            });

            gsap.to(visibleElements, { x: ev.axesEvent.delta.flick * 2 });
        });
    },

    killSwiper() {
        if (this.flicking) {
            document
                .querySelector("[x-ref=logo]")
                .classList.remove("is-collapsed");
            gsap.set([this.$refs.boxesWrapper], { clearProps: "transform" });
            this.flicking.destroy();
        }
    },

    setCurrentPanel(panel) {
        let dataIdValue = panel.getAttribute("data-id");
        if (dataIdValue === "fake") {
            dataIdValue = panel.getAttribute("data-fake-id");
            const fakePanel = [...this.$refs.boxesWrapper.children].find(
                (box) => box.getAttribute("data-id") === dataIdValue,
            );

            const bg = fakePanel.querySelector(".bg-to-expand");

            this.switchImages(dataIdValue);
            gsap.set(".bg-to-expand", {
                backgroundColor: "rgba(227, 246, 255, 0.8)",
            });

            if (bg) gsap.set(bg, { backgroundColor: "#7ED9FF" });

            return;
        }

        if (dataIdValue) {
            const bg = panel.querySelector(".bg-to-expand");
            this.switchImages(dataIdValue);
            gsap.set(".bg-to-expand", {
                backgroundColor: "rgba(227, 246, 255, 0.8)",
            });
            if (bg) gsap.set(bg, { backgroundColor: "#7ED9FF" });
        }
    },

    clearCurrentSlide() {
        if (this.currentImageId === 0) return;

        this.zIndex++;
        const currentImage = [...this.$refs.imagesContainer.children].find(
            (image) => image.getAttribute("data-id") === "0",
        );

        gsap.set(".bg-to-expand", {
            backgroundColor: "rgba(227, 246, 255, 0.8)",
        });
        gsap.set(currentImage, {
            clipPath: "url(#clip-path-0)",
            zIndex: this.zIndex,
        });

        this.currentImageId = 0;

        [...this.$refs.imagesContainer.children].forEach((image) => {
            if (image !== currentImage)
                gsap.set(image, { clearProps: "clipPath" });
        });

        this.inImageTransition(0, () => {
            gsap.set(currentImage, { clearProps: "clipPath" });
        });
    },

    switchImages(id) {
        if (id && this.currentImageId !== id) {
            this.zIndex++;
            this.currentImageId = id;
            const currentImage = [...this.$refs.imagesContainer.children].find(
                (image) => image.getAttribute("data-id") === id,
            );

            gsap.set(currentImage, {
                clipPath: `url(#clip-path-${id || 0})`,
                zIndex: this.zIndex,
            });

            this.inImageTransition(id, () => {
                gsap.set(currentImage, { clearProps: "clipPath" });
            });
        }
    },

    inImageTransition(id, done) {
        const inPathDom = document.querySelectorAll(
            `#clip-path-${id || 0} path`,
        );

        inPathDom.forEach((el, i) => {
            gsap.set(el, {
                attr: () => {
                    return {
                        d: this.inPath[i].d,
                    };
                },
            });
        });

        gsap.timeline({ onComplete: done })
            .set(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.inPath[i].d,
                    };
                },
            })
            .to(inPathDom, {
                attr: (i) => {
                    return {
                        d: this.inPathMiddle[i].d,
                        // d: outPath[i].d,
                    };
                },
                opacity: 1,
                ease: "Power2.easeOut",
                duration: 0.3,
                stagger: {
                    amount: 0.6,
                    grid: [this.yCounter, this.xCounter],
                    from: [1, 0],
                },
            })
            .to(
                inPathDom,
                {
                    attr: (i) => {
                        return {
                            d: this.outPath[i].d ?? null,
                            // d: outPath[i].d,
                        };
                    },
                    opacity: 1,
                    ease: "Power2.easeOut",
                    duration: 0.7,
                    stagger: {
                        amount: 0.8,
                        grid: [this.yCounter, this.xCounter],
                        from: [1, 0],
                    },
                },
                "<10%",
            );
    },

    killSmoothScroll() {
        if (this.smoothSc) {
            this.smoothSc.kill();
        }
    },

    createFilterSVG() {
        this.xCounter = Math.ceil(window.innerWidth / this.xWidth) + 1;
        this.yCounter = Math.ceil(window.innerHeight / this.yWidth);

        if (this.xCounter > 32 || this.yCounter > 32) {
            const newWidth = window.innerWidth / 32; // max number of paths in row
            const scale = newWidth / this.xWidth;

            this.xWidth = newWidth;
            this.yWidth *= scale;
            this.slashWidth *= scale;

            this.xCounter = Math.ceil(window.innerWidth / this.xWidth) + 1;
            this.yCounter = Math.ceil(window.innerHeight / this.yWidth);
        }

        for (let row = 0; row < this.yCounter; row++) {
            for (let x = 0; x < this.xCounter; x++) {
                const tempPathIn = `M${(x + 1) * this.xWidth - this.slashWidth} ${row * this.yWidth} H${(x + 1) * this.xWidth} L${
                    x * this.xWidth + this.slashWidth
                } ${this.yWidth * (row + 1)} H${x * this.xWidth} L${(x + 1) * this.xWidth - this.slashWidth} ${row * this.yWidth}Z `;

                const tempPathIn0 = `M${(x + 1) * this.xWidth - this.slashWidth0} ${row * this.yWidth} H${(x + 1) * this.xWidth} L${
                    x * this.xWidth + this.slashWidth0
                } ${this.yWidth * (row + 1)} H${x * this.xWidth} L${(x + 1) * this.xWidth - this.slashWidth0} ${row * this.yWidth}Z `;

                const tempPathOut = `
                  M${x * this.xWidth - this.slashWidth} ${row * this.yWidth}
                  H${(x + 1) * this.xWidth}
                  L${x * this.xWidth + this.slashWidth} ${this.yWidth * (row + 1) + 1}
                  H${(x - 1) * this.xWidth}
                  L${x * this.xWidth - this.slashWidth} ${row * this.yWidth}Z `;

                this.inPath.push({ d: tempPathIn0 });
                this.inPathMiddle.push({ d: tempPathIn });
                this.outPath.push({ d: tempPathOut });
            }
        }

        Array.from(this.$refs.imagesContainer.children).forEach((el, i) => {
            const id = el.getAttribute("data-id") || 0;
            const localInPath = [...this.inPath];
            const clipPath = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "clipPath",
            );

            clipPath.setAttribute("id", `clip-path-${id}`);
            localInPath.map((path) => {
                const newPathFrom = document.createElementNS(
                    "http://www.w3.org/2000/svg",
                    "path",
                );
                newPathFrom.setAttribute("d", path.d);
                clipPath.appendChild(newPathFrom);
            });
            this.$refs.patternDef.appendChild(clipPath);
        });
    },

    regenerateFilterSvg() {
        this.$refs.patternDef.innerHTML = "";
        this.inPath = [];
        this.inPathMiddle = [];
        this.outPath = [];
        this.createFilterSVG();
    },

    get isLarge() {
        return this.$store.breakpoint.lg;
    },
});
