import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        linksElements: "[data-hp-link]",
        webglMap: "[data-webgl-map]",
    },
    inElements: null,
    activeLink: null,
    isLinkVisible: false,
    isMapVisible: false,

    init() {
        this.linksElements = Array.from(
            this.$el.querySelectorAll(this.selectors.linksElements),
        );

        this.webglElement = this.$el.querySelector(this.selectors.webglMap);

        // this.$nextTick(() => {
        //     this.startScrollTrigger();
        // });
    },

    setActiveLink(jsonObject) {
        this.isLinkVisible = true;
        this.activeLink = jsonObject;
    },

    clearActiveLink() {
        this.isLinkVisible = false;
    },

    startScrollTrigger() {
        this.linksElements.forEach((el, index) => {
            const elData = el.getAttribute("data-hp-link"); // json decode;
            const jsonObject = JSON.parse(elData);

            ScrollTrigger.create({
                trigger: el.parentElement,
                start: "top center+=25%",
                end: () => {
                    if (index === this.linksElements.length - 1)
                        return "bottom bottom";

                    return "bottom top";
                },
                scrub: 1,
                markers: false,
                onToggle: (self) => {
                    if (self.isActive) {
                        this.setActiveLink(jsonObject);
                    } else {
                        // this.clearActiveLink();
                    }
                },
                onLeaveBack: () => {
                    if (index === 0) {
                        this.clearActiveLink();
                    }
                },
                onLeave: () => {
                    if (index === this.linksElements.length - 1) {
                        this.clearActiveLink();
                    }
                },
            });
        });
    },

    destroy() {},

    setScene() {},

    get transformWebgl() {
        return !this.isMapVisible && this.isLinkVisible;
    },

    get bigWebglVisible() {
        return this.isMapVisible;
    },

    get showActiveLink() {
        // return !this.isMapVisible && this.isLinkVisible;
        return this.isLinkVisible;
    },
});
