const xsMediaQuery = window.matchMedia("(min-width: 390px)");
const smMediaQuery = window.matchMedia("(min-width: 640px)");
const mdMediaQuery = window.matchMedia("(min-width: 768px)");
const lgMediaQuery = window.matchMedia("(min-width: 1024px)");

export default {
    xs: xsMediaQuery.matches,
    sm: smMediaQuery.matches,
    md: mdMediaQuery.matches,
    lg: lgMediaQuery.matches,

    init() {
        xsMediaQuery.addEventListener("change", (e) =>
            this.setBreakpoint("xs", e.matches),
        );
        smMediaQuery.addEventListener("change", (e) =>
            this.setBreakpoint("sm", e.matches),
        );
        mdMediaQuery.addEventListener("change", (e) =>
            this.setBreakpoint("md", e.matches),
        );
        lgMediaQuery.addEventListener("change", (e) =>
            this.setBreakpoint("lg", e.matches),
        );
    },

    setBreakpoint(name, matches) {
        this[name] = matches;
    },
};
