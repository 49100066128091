import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import debounce from "../utils/debounce";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        teaser: "[data-content-section-teaser]",
    },
    tween: [],
    teaser: null,
    initWindowWidth: 0,

    init() {
        this.teaser = Array.from(
            this.$el.querySelectorAll(this.selectors.teaser),
        );

        this.initWindowWidth = window.innerWidth;

        this.$nextTick(() => {
            this.startScrollTrigger();
        });

        gsap.set([this.$refs.videoDesktop, this.$refs.videoMobile], {
            scale: 1.15,
        });
        this.boundMouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.boundMouseLeaveHandler = this.mouseMoveLeave.bind(this);

        this.$el.addEventListener("mousemove", this.boundMouseMoveHandler);

        this.$el.addEventListener("mouseleave", this.boundMouseLeaveHandler);
    },

    destroy() {
        this.$el.removeEventListener("mousemove", this.boundMouseMoveHandler);
        this.$el.removeEventListener("mouseleave", this.boundMouseLeaveHandler);
    },

    mouseMoveLeave() {
        if (this.videoMove) {
            this.videoMove.pause();
        }

        if (this.leadGrayMove) {
            this.leadGrayMove.pause();
        }
        if (this.leadMove) {
            this.leadMove.pause();
        }

        gsap.to(
            [
                this.$refs.videoDesktop,
                this.$refs.videoMobile,
                this.$refs.leadGray,
                this.$refs.leadGrayMove,
            ],
            {
                y: 0,
                x: 0,
                duration: 1,
                ease: "power2.out",
            },
        );
    },

    mouseMoveHandler(event) {
        if (this.isTouchDevice()) return;

        // Get the bounding rectangle of the element
        const rect = this.$refs.video.getBoundingClientRect();

        // Calculate the mouse's Y position relative to the element's top edge
        const mouseY = event.clientY - rect.top;
        const mouseX = event.clientX - rect.left;

        // Normalize the value to get -50% at the top, 0% in the middle, and 50% at the bottom
        const percentY = (mouseY / rect.height - 0.5) * 60;
        const percentX = (mouseX / rect.width - 0.5) * -rect.width * 0.1;

        const imageY = (mouseY / rect.height - 0.5) * 80;
        const imageX = (mouseX / rect.width - 0.5) * 80;

        if (this.videoMove) {
            this.videoMove.pause();
        }

        if (this.leadGrayMove) {
            this.leadGrayMove.pause();
        }
        if (this.leadMove) {
            this.leadMove.pause();
        }

        this.videoMove = gsap.to(
            [this.$refs.videoDesktop, this.$refs.videoMobile],
            {
                y: `${-imageY}px`,
                x: `${-imageX}px`,
                duration: 1.5,
                ease: "power2.out",
            },
        );

        this.leadMove = gsap.to(this.$refs.leadYellow, {
            y: `${imageY * 0.5}px`,
            x: `${imageX * 0.5}px`,
            duration: 1.5,
            ease: "power2.out",
        });
        this.leadGrayMove = gsap.to(this.$refs.leadGray, {
            y: `${imageY * 0.25}px`,
            x: `${imageX * 0.25}px`,
            duration: 1.5,
            ease: "power2.out",
        });
    },

    onResize() {
        if (this.initWindowWidth === window.innerWidth) return;

        if (this.tween && this.tween.length) {
            this.tween.forEach((el) => {
                el.kill();
            });

            gsap.set(this.teaser, {
                clearProps: "translate,transform,rotate,scale",
            });

            this.tween = [];
        }

        this.$nextTick(() => {
            this.startScrollTrigger();
        });
    },

    startScrollTrigger() {
        this.teaser.forEach((el) => {
            this.tween.push(
                gsap.to(el, {
                    y: this.isMedium ? 150 : 50,
                    scrollTrigger: {
                        trigger: this.$el,
                        start: "top top",
                        end: "bottom top",
                        scrub: 2,
                    },
                }),
            );
        });
    },

    isTouchDevice() {
        return (
            "ontouchstart" in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0
        );
    },

    get isMedium() {
        return this.$store.breakpoint.md;
    },
});
