import { match } from "path-to-regexp";
import swup, {
    discoverStoriesPattern,
    homePattern,
    isHomePreviewPage,
} from "../swup.js";
import gsap from "gsap";

export default () => ({
    isOpen: false,
    currentUri: null,
    hideLogoDistance: 100,
    isScrolled: false,
    menuTween: null,
    openMenuTween: null,
    closeMenuTween: null,
    toMobileMenuTween: null,
    showMenu: false,
    disableMenu: false,

    init() {
        this.currentUri = swup.getCurrentUrl();

        swup.hooks.on("page:view", (visit) => {
            this.currentUri = visit.to.url;
            this.disableMenu = false;

            if (this.isOpen && !this.isDesktop) {
                this.toggleMenu();
            } else {
                this.closeOverlay();
                this.menuTween = null;
                if (this.isOpen) {
                    this.isOpen = false;
                    this.setMobileMenu(false);
                }
            }
        });

        this.$watch("isMobileMenu", (value) => {
            if (!this.isDesktop) {
                return;
            }

            if (!this.isOnStoryPage) {
                this.setMobileMenu(value);
            }

            if (!value) {
                this.closeOverlay();
                this.isOpen = false;
            }
        });

        this.$nextTick(() => {
            this.showMenu = true;
        });
    },

    storyHpStateChange(event) {
        const { isOpen } = event.detail;

        if (isOpen) {
            this.disableMenu = true;
        } else {
            this.disableMenu = false;
        }
    },

    onScroll() {
        this.isScrolled = window.scrollY >= this.hideLogoDistance;

        if (this.isMobileMenu && this.isOpen) {
            this.toggleMenu(false);
        }
    },

    onResize() {
        if (this.isOpen) {
            this.toggleMenu();
        }
    },

    toggleMenu() {
        if (!this.menuTween) {
            this.menuTween = gsap
                .timeline({
                    paused: true,
                    onReverseComplete: () => {
                        if (!this.isMobileMenu) {
                            gsap.set(
                                [
                                    this.$refs.menuContainer,
                                    this.$refs.menuContainer.querySelectorAll(
                                        "li",
                                    ),
                                ],
                                { clearProps: "all" },
                            );
                        }
                    },
                })
                .set(this.$refs.menuCover, { height: 0 })
                .set(this.$refs.menuCover, { height: "100vh" })
                .set(this.$refs.menuContainer.querySelectorAll("li"), {
                    autoAlpha: 0,
                    x: 10,
                })
                .fromTo(
                    this.$refs.menuCover,
                    { opacity: 0 },
                    { opacity: 1, duration: 0.3 },
                )
                .fromTo(
                    this.$refs.menuLabel,
                    { opacity: 1 },
                    { opacity: 0, duration: 0.3 },
                    "<50%",
                )
                .fromTo(
                    this.$refs.menuContainer,
                    { autoAlpha: 0 },
                    { autoAlpha: 1, duration: 0.1 },
                    "<100%",
                )
                .fromTo(
                    this.$refs.menuContainer.querySelectorAll("li"),
                    { autoAlpha: 0, x: 10 },
                    { duration: 0.3, x: 0, autoAlpha: 1, stagger: 0.05 },
                );
        }

        if (this.isOpen) {
            this.menuTween.reverse();
        } else {
            this.menuTween.play();
        }

        this.isOpen = !this.isOpen;
    },

    setMobileMenu(direction) {
        if (!this.toMobileMenuTween) {
            this.toMobileMenuTween = gsap
                .timeline({
                    paused: true,
                    onReverseComplete: () => {
                        gsap.set(
                            [
                                this.$refs.burger,
                                this.$refs.menuContainer,
                                this.$refs.menuContainer.querySelectorAll("li"),
                            ],
                            { clearProps: "all" },
                        );
                    },
                })
                // .fromTo(
                //     this.$refs.menuContainer.querySelectorAll("li"),
                //     { autoAlpha: 1, x: 0 },
                //     {
                //         duration: 0.3,
                //         x: this.isOpen ? 0 : 10,
                //         autoAlpha: this.isOpen ? 1 : 0,
                //         stagger: 0.05,
                //     },
                // )
                .to(this.$refs.burger, { y: 0 })
                .fromTo(
                    this.$refs.menuContainer,
                    { y: 0, autoAlpha: 1 },
                    { y: 60, autoAlpha: 0 },
                    "<",
                )
                .timeScale(2);
            // .to(this.$refs.menuContainer, { autoAlpha: 0, duration: 0.1 });
        }

        if (direction) {
            this.toMobileMenuTween.timeScale(2).play();
        } else {
            this.toMobileMenuTween.timeScale(3).reverse();
        }
    },

    closeOverlay() {
        if (this.isOpen) {
            gsap.timeline({
                paused: true,
            })
                .set(this.$refs.menuCover, { height: 0 })
                .set(this.$refs.menuCover, { height: "100vh" })
                .fromTo(
                    this.$refs.menuCover,
                    { opacity: 0 },
                    { opacity: 1, duration: 0.3 },
                )
                .reverse();
        }
    },

    get isOnHomePage() {
        return (
            !!match(homePattern)(this.currentUri) ||
            !!match(isHomePreviewPage)(this.currentUri)
        );
    },

    get isOnStoryPage() {
        return !!match(discoverStoriesPattern, {
            decode: decodeURIComponent,
        })(this.currentUri);
    },

    get isDesktop() {
        return this.$store.breakpoint.lg;
    },

    get openState() {
        return this.isOpen || this.isDesktop;
    },

    get collapseMenu() {
        return this.isScrolled;
    },

    get isMobileMenu() {
        return this.isScrolled && this.isDesktop;
    },

    get isPageScrolled() {
        return this.isScrolled;
    },
});
