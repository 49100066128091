import Swiper from "swiper";
import "swiper/css";

export default () => ({
    swiper: null,

    init() {
        this.initSwiper();
    },

    initSwiper() {
        if (this.swiper) {
            return;
        }

        this.swiper = new Swiper(this.$refs.swiper, {
            slidesPerView: "auto",
            spaceBetween: 20,
            initialSlide: Math.min(
                this.$refs.swiper.querySelectorAll(".swiper-slide").length - 1,
                2,
            ),

            breakpoints: {
                768: {
                    spaceBetween: 30,
                },
                1024: {
                    spaceBetween: 40,
                },
            },
        });

        setTimeout(
            () => {
                this.swiper.slideTo(0, 1200, false);
            },
            400 + Math.min(6,this.$refs.swiper.querySelectorAll(".swiper-slide").length) * 195,
        );
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },
});
