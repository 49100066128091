import swup from "../swup.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default () => ({
    selectors: {
        inElements: "[data-story-in-animation]",
    },
    inElements: null,

    init() {
        this.inElements = Array.from(
            this.$el.querySelectorAll(this.selectors.inElements),
        );

        // sort elements with passed value to attribute first
        this.inElements.sort((a, b) => {
            const aValue = a.getAttribute("data-story-in-animation");
            const bValue = b.getAttribute("data-story-in-animation");

            if (aValue === "" && bValue !== "") return 1;
            if (aValue !== "" && bValue === "") return -1;
            return 0;
        });

        this.$nextTick(() => {
            this.setScene();
        });
    },

    destroy() {
        ScrollTrigger.killAll();
    },

    setScene() {
        gsap.set(this.inElements, { opacity: 0 });
        this.initScrollTrigger();
    },

    initScrollTrigger() {
        ScrollTrigger.batch(this.inElements, {
            start: "top bottom-=50",
            once: true,
            onEnter: (batch) => {
                gsap.fromTo(
                    batch,
                    { y: 30 },
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.1,
                        duration: 0.45,
                        ease: "circle.out",
                    },
                );
            },
        });
    },
});
