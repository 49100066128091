import swup from "../swup.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger, SplitText);

export default () => ({
    selectors: {
        inElements: "[data-story-in-animation]",
        imageOut: "[data-story-image-out]",
        splitText: "[data-story-split-text]",
    },
    inElements: null,
    elHeight: 0,
    forceScroll: {
        diff: 0,
        switchAfter: 100,
        isTouching: false,
    },

    init() {
        this.inElements = this.$el.querySelectorAll(this.selectors.inElements);
        this.splitTextEl = this.$el.querySelectorAll(this.selectors.splitText);
        this.imgOutElements = this.$el.querySelectorAll(
            this.selectors.imageOut,
        );

        this.$nextTick(() => {
            this.setScene();
        });
    },

    destroy() {
        ScrollTrigger.killAll();
    },

    onResize() {
        this.setSpaceForNextStoryModule();
    },

    onTouchStart() {
        this.forceScroll.isTouching = true;
    },

    onTouchEnd() {
        if (this.forceScroll.isTouching) {
            if (this.forceScroll.diff > this.forceScroll.switchAfter) {
                this.forceScroll.isTouching = false;
                this.forceScroll.diff = 0;
                swup.navigate(this.$refs.nextStoryUrl.getAttribute("href"));
            } else {
                this.forceScroll.isTouching = false;
                this.forceScroll.diff = 0;
                gsap.to(this.$refs.nextStoryModule, {
                    height: parseFloat(this.$el.style.marginBottom),
                });
            }
        }
    },

    onTouchMove() {
        this.elHeight = Math.round(
            document.body.offsetHeight - window.innerHeight,
        );
        this.forceScroll.diff = Math.round(window.scrollY) - this.elHeight;

        if (this.forceScroll.isTouching && this.forceScroll.diff > 0) {
            if (this.forceScroll.diff > this.forceScroll.switchAfter) {
                this.forceScroll.isTouching = false;
                this.forceScroll.diff = 0;
                swup.navigate(this.$refs.nextStoryUrl.getAttribute("href"));
            } else {
                const height =
                    10 +
                    parseFloat(this.$el.style.marginBottom) +
                    Math.min(
                        this.forceScroll.diff,
                        this.forceScroll.switchAfter,
                    );

                gsap.to(this.$refs.nextStoryModule, {
                    height,
                });
            }
        }
    },

    setScene() {
        gsap.set(this.inElements, { opacity: 0 });
        this.initScrollTrigger();
        this.setSpaceForNextStoryModule();
    },

    initScrollTrigger() {
        this.initGsapSplitTextAnimation();
        ScrollTrigger.batch(this.inElements, {
            start: "top bottom-=50",
            once: true,
            onEnter: (batch) => {
                gsap.fromTo(
                    batch,
                    { y: 100 },
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.15,
                        duration: 0.9,
                        ease: "circle.out",
                    },
                );
            },
        });

        gsap.to(this.imgOutElements, {
            scale: 1.1,
            transformOrigin: "center bottom",
            scrollTrigger: {
                trigger: this.imgOutElements,
                start: "top top",
                end: "bottom top",
                scrub: 0.5,
            },
        });
    },

    initGsapSplitTextAnimation() {
        this.splitTextEl.forEach((el) => {
            const splitText = new SplitText(el, { type: "words,chars" });
            const lines = splitText.chars;

            gsap.fromTo(
                lines,
                {
                    "will-change": "transform",
                    transformOrigin: "0% 50%",
                    opacity: 0,
                    y: 10,
                },
                {
                    ease: "power3.in",
                    opacity: 1,
                    y: 0,
                    stagger: 0.005,
                    duration: 0.4,
                    scrollTrigger: {
                        trigger: el,
                        once: true,
                        start: "top bottom-=50",
                    },
                },
            );
        });
    },

    setSpaceForNextStoryModule() {
        if (this.$refs.nextStoryModule) {
            const nextStoryModuleHeight =
                this.$refs.nextStoryModule.offsetHeight;
            this.$el.style.marginBottom = `${nextStoryModuleHeight}px`;
            this.$refs.container.style.minHeight = `calc(100vh - ${nextStoryModuleHeight}px)`;
        }
    },
});
