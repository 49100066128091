import Swiper from "swiper";
import { Pagination, Keyboard } from "swiper/modules";
import "swiper/css";

export default () => ({
    swiper: null,

    init() {
        // todo: init lazily
        this.initSwiper();
    },

    initSwiper() {
        if (this.swiper) {
            return;
        }

        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Pagination, Keyboard],
            slidesPerView: "auto",
            spaceBetween: 20,
            // touchEventsTarget: "container",
            touchStartForcePreventDefault: true,
            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true,
            },

            breakpoints: {
                768: {
                    spaceBetween: 30,
                },
                1024: {
                    spaceBetween: 40,
                },
            },
        });

        this.swiper.on("slideChange", () => {
            this.$dispatch("swiper-slide-change", this.swiper.realIndex);
        });
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },
});
