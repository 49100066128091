import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/dist/photoswipe.css";

const SEL_PHOTOSWIPE_ITEM = "[data-photoswipe-item]";

const closeSVG = `
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2L16.1421 16.1421" stroke="#363B3E" stroke-width="2" stroke-linecap="round"/>
        <path d="M16.1421 2L1.99995 16.1421" stroke="#363B3E" stroke-width="2" stroke-linecap="round"/>
    </svg>
`;

const arrowPrevSVG = `
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.33337 11.666L2.08337 6.41602L7.33337 1.16602" stroke="#363B3E" stroke-width="2" stroke-linecap="round"/>
    </svg>
`;

const arrowNextSVG = `
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.66675 11.666L6.91675 6.41602L1.66675 1.16602" stroke="#363B3E" stroke-width="2" stroke-linecap="round"/>
    </svg>
`;

export default () => ({
    lightbox: null,

    init() {
        this.loadPhotoswipeGallery();
    },

    loadPhotoswipeGallery() {
        const children = this.$el.querySelectorAll(SEL_PHOTOSWIPE_ITEM);

        const lightbox = new PhotoSwipeLightbox({
            gallery: this.$el,
            children,
            pswpModule: PhotoSwipe,
            zoom: false,
            counter: false,
            bgOpacity: 0.95,
            closeSVG,
            arrowPrevSVG,
            arrowNextSVG,
            tapAction: "close",
            doubleTapAction: false,
            padding: { top: 80, bottom: 100, left: 20, right: 20 },
        });

        // Add captions
        lightbox.on("uiRegister", () => {
            lightbox.pswp.ui.registerElement({
                name: "custom-caption",
                order: 9,
                isButton: false,
                appendTo: "root",
                html: "Caption text",
                onInit: (el, pswp) => {
                    lightbox.pswp.on("change", () => {
                        const currSlideElement =
                            lightbox.pswp.currSlide.data.element;
                        el.innerHTML = currSlideElement
                            ? currSlideElement.getAttribute("data-caption")
                            : "";
                    });
                },
            });

            // Add bullet navigation
            lightbox.pswp.ui.registerElement({
                name: "bulletsIndicator",
                className: "pswp__bullets",
                appendTo: "root",
                onInit: (el, pswp) => {
                    const bullets = [];
                    let bullet;
                    let prevIndex = -1;

                    for (let i = 0; i < pswp.getNumItems(); i++) {
                        bullet = document.createElement("div");
                        bullet.className = "pswp__bullet";
                        bullet.onclick = (e) => {
                            pswp.goTo(bullets.indexOf(e.target));
                        };
                        el.appendChild(bullet);
                        bullets.push(bullet);
                    }

                    pswp.on("change", (a) => {
                        if (prevIndex >= 0) {
                            bullets[prevIndex].classList.remove(
                                "pswp__bullet--active",
                            );
                        }
                        bullets[pswp.currIndex].classList.add(
                            "pswp__bullet--active",
                        );
                        prevIndex = pswp.currIndex;
                    });
                },
            });
        });

        // Required to position that pattern thing at the bottom right of the image
        lightbox.on("imageSizeChange", ({ width, height, slide }) => {
            slide.container.style.width = `${width}px`;
            slide.container.style.height = `${height}px`;
        });

        lightbox.init();

        this.lightbox = lightbox;
    },

    destroy() {
        if (this.lightbox) {
            this.lightbox.destroy();
        }
    },
});
