import Swiper from "swiper";
import { Parallax, Keyboard } from "swiper/modules";
import "swiper/css";

export default () => ({
    swiper: null,

    init() {
        this.initSwiper();
        this.$watch("isDesktop", () => {
            this.initSwiper();
        });
    },

    initSwiper() {
        if (this.isDesktop || this.swiper) {
            return;
        }

        this.swiper = new Swiper(this.$refs.swiper, {
            modules: [Parallax, Keyboard],
            parallax: true,
            keyboard: true,
        });
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    get isDesktop() {
        return this.$store.breakpoint.md;
    },
});
