import Swiper from "swiper";
import "swiper/css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default (slugs) => ({
    selectedIndex: 0,
    slugs,
    swiper: null,

    init() {
        // read the anchor from the current url
        // and switch to the respective tab
        // const { hash } = Location.fromUrl(window.location.href);
        const hash = window.location.href.split("#")[1] ?? null;
        const slugItems = Object.values(this.slugs);

        if (hash && slugItems.includes(hash)) {
            this.selectedIndex = slugItems.indexOf(hash);
        }

        // on tab navigation, update the hash
        this.$watch("selectedIndex", () => {
            if (slugItems[this.selectedIndex]) {
                window.location.hash = slugItems[this.selectedIndex];
            }
            this.$dispatch("tabSwitched");
        });

        this.initSwiper();
        this.initScrollTrigger();
    },

    initSwiper() {
        if (this.swiper) {
            return;
        }

        this.swiper = new Swiper(this.$refs.swiper, {
            slidesPerView: "auto",
            spaceBetween: 0,
            focusableElements: "input, select, option, textarea, video, label",
            initialSlide: Object.values(this.slugs).length - 1,
        });
    },

    initScrollTrigger() {
        ScrollTrigger.create({
            trigger: this.$el,
            start: "top bottom-=100",
            onEnter: () => {
                if (this.swiper) {
                    this.swiper.slideTo(
                        0,
                        300 + 200 * Object.values(this.slugs).length,
                    );
                }
            },
        });
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }

        ScrollTrigger.killAll();
    },
});
