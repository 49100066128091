import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default (stories) => ({
    stories,
    isOpen: false,
    currentStory: null,

    init() {
        this.onHashChange();

        window.addEventListener("hashchange", this.onHashChange);
    },

    onHashChange() {
        if (!this.stories || !this.stories.length) return;

        this.currentStory = this.stories.find(
            (v) => v.slug === window.location.hash.replace("#", ""),
        );

        if (this.currentStory) {
            this.openStory();
        }
    },

    destroy() {
        window.removeEventListener("hashchange", this.onHashChange);
    },

    openStory() {
        this.callForStory();
        this.$dispatch("disable-other-stories", {
            index: -1,
            disable: false,
        });

        gtag("event", "page_view", {
            page_title: this.currentStory.title,
            page_location: window.location.href,
        });
    },

    closeStory(event) {
        const { currentOpenIndex } = event.detail;

        if (!this.isOpen) {
            return;
        }

        this.isOpen = false;

        this.$dispatch("story-is-open", { isOpen: false });
        history.pushState(
            null,
            "",
            window.location.pathname + window.location.search,
        );

        this.$dispatch("close-modal", {
            container: this.$el,
            index: -1,
        });
    },

    callForStory() {
        fetch(`stories/render/${this.currentStory.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.text())
            .then((html) => {
                this.isOpen = true;

                this.$dispatch("open-modal", {
                    container: this.$el,
                    html,
                    currentOpenIndex: this.index,
                    buildingKey: this.currentStory.buildingKey,
                });

                this.$dispatch("story-is-open", { isOpen: true });

                if (window.location.hash !== `#${this.currentStory.slug}`) {
                    history.pushState(null, "", `#${this.currentStory.slug}`);
                }
            })
            .catch((error) => {
                console.error("Error while loading story:", error);
            });
    },
});
