import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

const SEL_MASONRY_GRID = "[data-masonry-grid]";
const SEL_MASONRY_ITEM = "[data-masonry-item]";
const SEL_MASONRY_STAMP = "[data-masonry-stamp]";
const SEL_MASONRY_COLUMN_SIZER = "[data-masonry-column-sizer]";
const SEL_MASONRY_GUTTER_SIZER = "[data-masonry-gutter-sizer]";

export default () => ({
    masonry: null,
    tabSwitchHandler: null,
    initialised: false,

    init() {
        this.tabSwitchHandler = this.tabSwitched.bind(this);
        this.loadMasonry();
    },

    loadMasonry() {
        const masonryEl = this.$el;
        if (!masonryEl) return;

        this.masonry = new Masonry(masonryEl, {
            itemSelector: SEL_MASONRY_ITEM,
            columnWidth: SEL_MASONRY_COLUMN_SIZER,
            gutter: SEL_MASONRY_GUTTER_SIZER,
            percentPosition: true,
            transitionDuration: 0,
            horizontalOrder: true,
            stamp: SEL_MASONRY_STAMP,
        });

        // Ensure layout is correct after images are loaded
        imagesLoaded(masonryEl, () => {
            this.tabSwitchHandler();
        });

        window.addEventListener("tabSwitched", this.tabSwitchHandler);
    },

    tabSwitched() {
        setTimeout(() => {
            this.masonry.layout();
            this.initialised = true;
        }, 50);
    },

    destroy() {
        if (this.masonry) {
            this.masonry.destroy();
        }

        window.removeEventListener("tabSwitched", this.tabSwitchHandler);
    },
});
